import { createStore } from "redux";
import CookieUtil from "../Util/CookieUtil";

const initialState = {
    loggedIn: CookieUtil.getCookie("loggedIn") === "true" ? true : false
};

const reducer = (state = initialState, action) => {
    // console.log("reducer running", action);
    if (action.type === "LOGIN") {
        document.cookie = "loggedIn=true"
        return Object.assign({}, state, {
            userData: action.userData,
            loggedIn: true
        });
    } else if (action.type === "LOGOUT") {
        document.cookie = "loggedIn=false"
        return Object.assign({}, state, { loggedIn: false });
    } else if (action.type === "CHANGE_LANG") {
        console.log("setting language to: ", action.languageSetting)
        document.cookie = "lang="+action.languageSetting;
        return Object.assign({}, state, { languageSetting: action.languageSetting });
    }
    return state;
};

const store = createStore(reducer);

export default store;
