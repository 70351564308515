export function login(payload) {
    return {
        type: "LOGIN",
        loggedIn: true,
        userData: payload
    };
}

export function logout(){
    return {
        type: "LOGOUT",
        loggedIn: false
    }
}

export function changeLanguage(languageSetting){
    return {
        type: "CHANGE_LANG",
        languageSetting: languageSetting
    }
}
