import React, { Fragment } from "react";
import { Button, Form, Grid, Header, Input, Message } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import TokenUtil from "../Util/TokenUtil";
import Constants from "../Util/Constants";
import CookieUtil from "../Util/CookieUtil";
import LanguagePicker from "./LanguagePicker";
import { connect } from "react-redux";
import {login} from "../actions";
import MiscTextEng from "../miscJson/miscText_eng";
import MiscTextKor from "../miscJson/miscText_kor";
import MiscTextJpn from "../miscJson/miscText_jpn";
import MiscTextDeu from "../miscJson/miscText_deu";
import MiscTextPor from "../miscJson/miscText_por";
import MiscTextSpa from "../miscJson/miscText_spa";
import MiscTextAra from "../miscJson/miscText_ara";
import MiscTextFra from "../miscJson/miscText_fra";
import MiscTextHin from "../miscJson/miscText_hin";
import MiscTextIta from "../miscJson/miscText_ita";
import MiscTextRus from "../miscJson/miscText_rus";
import MiscTextHbs from "../miscJson/miscText_hbs";

let miscText = MiscTextEng;

class Login extends React.Component {
    constructor(props) {
        super(props);
        let lang = CookieUtil.getCookie("lang") || "eng"
        // console.log(CookieUtil.getCookie("lang"))

        let loggedIn = CookieUtil.getCookie("loggedIn");
        if (loggedIn) {
            document.cookie = "loggedIn=false";
        } else {
            document.cookie = "loggedIn=true";
        }

        this.state = {
            redirect: false,
            error: false,
            email: "",
            languageSetting: lang
        };
    }

	updateLanguage = langCode => {
        switch (langCode) {
            case "eng":
                miscText = MiscTextEng;
                break;
            case "kor":
                miscText = MiscTextKor;
                break;
            case "jpn":
                miscText = MiscTextJpn;
                break;
            case "deu":
                miscText = MiscTextDeu;
                break;
            case "por":
                miscText = MiscTextPor;
                break;
            case "spa":
                miscText = MiscTextSpa;
                break;
            case "ara":
                miscText = MiscTextAra;
                break;
            case "fra":
                miscText = MiscTextFra;
                break;
            case "hin":
                miscText = MiscTextHin;
                break;
            case "ita":
                miscText = MiscTextIta;
                break;
            case "rus":
                miscText = MiscTextRus;
                break;
            case "hbs":
                miscText = MiscTextHbs;
                break;
            default:
                miscText = MiscTextEng;
        }
    };

    componentDidMount() {
        document.title = "FOMO Match";
        document.cookie = "currentPage=login";
    }

    handleLoginClick = async (e, data) => {
        this.setState({ error: false, errorMessage: "" });
        var email = this.state.email;
        console.log("logging in as: " + email);
        var token = TokenUtil.createToken(email);

        var url =
            Constants.baseApiUrl +
            "action=login" +
            "&token=" +
            token +
            "&email=" +
            email +
            "&password=" +
            this.state.password;

        url = encodeURI(url);

        await axios
            .get(url)
            .then(res => {
                console.log("response", res);
                if (res.data.status === "failure") {
                    this.setState({
                        error: true,
                        errorMessage: res.data.message
                    });
                } else {
                    document.cookie = "email=" + this.state.email;
                    document.cookie = "loggedIn=true";
                    document.cookie = "sessionId="+res.data.sessionid;
					// document.cookie = "lang="+res.data.langcode;
                    this.setState({ redirect: true, userData: res.data });
                    this.props.login(res.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    handleChange = (e, data) => {
        this.setState({ [data.name]: data.value });
    };

    handleLanguageChange = (langkey) => {
        this.setState({ languageSetting: langkey });
    };

    render() {
		this.updateLanguage(this.state.languageSetting);
        if (this.state.redirect) {
            return (
                <Redirect
                    push
                    to={{ pathname: "/Edit", userData: this.state.userData, showDoneMessage: true}}
                />
            );
        }

        return (
            <Fragment>
                <Grid
                    verticalAlign="middle"
                    textAlign="center"
                    style={{ height: "100vh", width: "100vw" }}
                >
                    <Grid.Column style={{ minWidth: 300, maxWidth: 800 }}>
                        {this.state.error && (
                            <Message error>{this.state.errorMessage}</Message>
                        )}
                        {this.props.location.isRecovery && (
                            <Message>
                                {miscText["canlogin"]}
                            </Message>
                        )}
                        <Form size="large">
                            <Input
                                style={{ margin: 5 }}
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder={miscText["email"]}
                                onChange={this.handleChange}
                                name="email"
                            />

                            <Input
                                style={{ margin: 5 }}
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder={miscText["password"]}
                                type="password"
                                name="password"
                                onChange={this.handleChange}
                            />
                            <Button
                                color="teal"
                                fluid
                                size="large"
                                onClick={this.handleLoginClick}
                            >
                                {miscText["login"]}
                            </Button>
                        </Form>
                        <Message compact>
                            <Link
                                to={{
                                    pathname: "/Register",
                                    languageSetting: this.state.languageSetting
                                }}
                            >
                                <Header>{miscText["signup"]}</Header>
                            </Link>
                        </Message>
                    </Grid.Column>
                </Grid>

                <LanguagePicker
                    handleChange={this.handleLanguageChange}
                    languageSetting={this.state.languageSetting}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state){
    return {
        loggedIn: state.loggedIn,
        userData: state.userData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (payload) => {
            console.log("login dispatch");
            dispatch(login(payload));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
