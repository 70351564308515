import React, { Fragment } from "react";
import { Button, Grid, Input, Message } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import ApiAction from "../Util/ApiAction";
import CookieUtil from "../Util/CookieUtil";
import LanguagePicker from "./LanguagePicker";
import MiscTextEng from "../miscJson/miscText_eng";
import MiscTextKor from "../miscJson/miscText_kor";
import MiscTextJpn from "../miscJson/miscText_jpn";
import MiscTextDeu from "../miscJson/miscText_deu";
import MiscTextPor from "../miscJson/miscText_por";
import MiscTextSpa from "../miscJson/miscText_spa";
import MiscTextAra from "../miscJson/miscText_ara";
import MiscTextFra from "../miscJson/miscText_fra";
import MiscTextHin from "../miscJson/miscText_hin";
import MiscTextIta from "../miscJson/miscText_ita";
import MiscTextRus from "../miscJson/miscText_rus";
import MiscTextHbs from "../miscJson/miscText_hbs";
import {connect} from "react-redux";
import {logout} from "../actions/index";

let miscText = MiscTextEng;

class Register extends React.Component {
    constructor(props) {
        super(props);
		let lang = CookieUtil.getCookie("lang") || "eng";
        this.state = {
            redirect: false,
            passMatched: false,
			email: "",
            languageSetting: lang
        };
    }

    componentDidMount(){
        this.props.logout();
    }

	updateLanguage = langCode => {
        switch (langCode) {
            case "eng":
                miscText = MiscTextEng;
                break;
            case "kor":
                miscText = MiscTextKor;
                break;
            case "jpn":
                miscText = MiscTextJpn;
                break;
            case "deu":
                miscText = MiscTextDeu;
                break;
            case "por":
                miscText = MiscTextPor;
                break;
            case "spa":
                miscText = MiscTextSpa;
                break;
            case "ara":
                miscText = MiscTextAra;
                break;
            case "fra":
                miscText = MiscTextFra;
                break;
            case "hin":
                miscText = MiscTextHin;
                break;
            case "ita":
                miscText = MiscTextIta;
                break;
            case "rus":
                miscText = MiscTextRus;
                break;
            case "hbs":
                miscText = MiscTextHbs;
                break;
            default:
                miscText = MiscTextEng;
        }
    };

    handleClick = async () => {
        let res = await ApiAction.createUser(
            this.state.email,
            this.state.languageSetting,
            this.state.password
        );

        if (res && res.data.status === "success") {
            this.setState({ redirect: true });
			document.cookie = "email=" + this.state.email;
			document.cookie = "loggedIn=true";
			document.cookie = "sessionId="+res.data.sessionid;
			document.cookie = "lang="+this.state.languageSetting;
        } else {
            this.setState({ error: true, errorMessage: res.data.message });
        }
    };

    handleChange = (e, data) => {
        this.setState({ [data.name]: data.value });

        if (data.name === "confirmPassword") {
            if (data.value === this.state.password) {
                this.setState({ passMatched: true });
            } else {
                this.setState({ passMatched: false });
            }
        } else if (data.name === "password") {
            if (data.value === this.state.confirmPassword) {
                this.setState({ passMatched: true });
            } else {
                this.setState({ passMatched: false });
            }
        }
        /*this.setState({ languageSetting: data.langkey });*/
    };

    handleConfirmPasswordBlur = () => {};

    handleLanguageChange = (langkey) => {
        this.setState({ languageSetting: langkey });
    };

    render() {
		this.updateLanguage(this.state.languageSetting);
        if (this.state.redirect) {
            document.cookie = "currentIndex=0";
			let lang = CookieUtil.getCookie("lang") || "eng";
            return (
                <Redirect
                    to={{
                        pathname: "/Questions",
                        email: this.state.email,
                        languageSetting: lang/*this.props.location.languageSetting*/
                    }}
                />
            );
        }

        let buttonDisabled = true;
        if (
            this.state.email &&
            this.state.confirmPassword &&
            this.state.passMatched
        ) {
            buttonDisabled = false;
        }

        return (
            <Fragment>
                <Grid
                    textAlign="center"
                    style={{ height: "100vh", width: "100vw" }}
                    verticalAlign="middle"
                >
                    <Grid.Column style={{ minWidth: 300, maxWidth: 800 }}>
                        <Message
                            content={
                                miscText["selectlang"]
                            }
                        />
                        {this.state.error && (
                            <Message error content={this.state.errorMessage} />
                        )}
                        <Input
                            style={{ marginBottom: 10 }}
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder={miscText["email"]}
                            onChange={this.handleChange}
                            name="email"
                        />
                        <Input
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder={miscText["password"]}
                            type="password"
                            name="password"
                            onChange={this.handleChange}
                            onBlur={this.handleConfirmPasswordBlur}
                        />
                        <Input
                            error={
                                this.state.confirmPassword &&
                                !this.state.passMatched
                            }
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder={miscText["confirm"]}
                            type="password"
                            name="confirmPassword"
                            onChange={this.handleChange}
                            onBlur={this.handleConfirmPasswordBlur}
                        />
                        <Button
                            color="teal"
                            fluid
                            size="large"
                            onClick={this.handleClick}
                            style={{ marginTop: 10 }}
                            disabled={buttonDisabled}
                        >
                            {miscText["next"]}
                        </Button>
                    </Grid.Column>
                </Grid>
                <LanguagePicker
                    handleChange={this.handleLanguageChange}
                    languageSetting={this.state.languageSetting}
                />
            </Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => {
            document.cookie = "loggedIn=false";
            dispatch(logout());
        }
    };
}

export default connect(null, mapDispatchToProps)(Register);
