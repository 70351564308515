import React from "react";
import { Input, Dropdown } from "semantic-ui-react";
import MiscTextEng from "../miscJson/miscText_eng";
import MiscTextKor from "../miscJson/miscText_kor";
import MiscTextJpn from "../miscJson/miscText_jpn";
import MiscTextDeu from "../miscJson/miscText_deu";
import MiscTextPor from "../miscJson/miscText_por";
import MiscTextSpa from "../miscJson/miscText_spa";
import MiscTextAra from "../miscJson/miscText_ara";
import MiscTextFra from "../miscJson/miscText_fra";
import MiscTextHin from "../miscJson/miscText_hin";
import MiscTextIta from "../miscJson/miscText_ita";
import MiscTextRus from "../miscJson/miscText_rus";
import MiscTextHbs from "../miscJson/miscText_hbs";
import CookieUtil from "./CookieUtil";

class InputComponentConstants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    
}

let getTextByKey = key => {
    let langCode = CookieUtil.getCookie("lang");
    let returnText = "";
    switch (langCode) {
        case "eng":
            returnText = MiscTextEng[key];
            break;
        case "kor":
            returnText = MiscTextKor["type"];
            break;
        case "jpn":
            returnText = MiscTextJpn[key];
            break;
        case "deu":
            returnText = MiscTextDeu[key];
            break;
        case "por":
            returnText = MiscTextPor[key];
            break;
        case "spa":
            returnText = MiscTextSpa[key];
            break;
        case "ara":
            returnText = MiscTextAra[key];
            break;
        case "fra":
            returnText = MiscTextFra[key];
            break;
        case "hin":
            returnText = MiscTextHin[key];
            break;
        case "ita":
            returnText = MiscTextIta[key];
            break;
        case "rus":
            returnText = MiscTextRus[key];
            break;
        case "hbs":
            returnText = MiscTextHbs[key];
            break;
        default:
            returnText = MiscTextEng[key];
    }

    return returnText;
};

InputComponentConstants.textInput = (config, handleChange, value) => (
    <Input
        // onKeyPress={e => {
        //     e.key === "Enter" && e.preventDefault();
        // }}
        fluid
        placeholder={getTextByKey("type")}
        onChange={handleChange(config.fieldName)}
        value={value || ""}
        name={config.fieldName}
    />
);

InputComponentConstants.dropdownSingleCategory = (
    config,
    handleChange,
    handleFocus,
    value,
    options,
    handleAdd
) => (
    <Dropdown
        style={{ margin: 0 }}
        noResultsMessage=""
        onKeyPress={e => {
            e.key === "Enter" && e.preventDefault();
        }}
        fluid
        name={config.fieldName}
        allowAdditions={config.fieldName === "occupation" ? true : false}
        onAddItem={handleAdd}
        additionLabel=""
        selection
        search
        className="field"
        onChange={handleChange}
        value={value}
        options={options}
        // placeholder={"Choose " + config.fieldName}
        onFocus={handleFocus}
    />
);

InputComponentConstants.dropdownMultipleCategory = (
    config,
    handleChange,
    handleAdd,
    handleFocus,
    value,
    options
) => (
    <Dropdown
        style={{ margin: 0 }}
        noResultsMessage=""
        onKeyPress={e => {
            e.key === "Enter" && e.preventDefault();
        }}
        fluid
        allowAdditions
        closeOnChange
        name={config.fieldName}
        selection
        search
        onAddItem={handleAdd}
        additionLabel=""
        multiple
        className="field"
        onChange={handleChange}
        value={value}
        options={options}
        placeholder={getTextByKey("suggestions")+getTextByKey("delimit")}
        onFocus={handleFocus}
    />
);

InputComponentConstants.dropdownSingleInput = (
    config,
    handleChange,
    handleSearch,
    value,
    options,
    handleAdd
) => (
    <Dropdown
        style={{ margin: 0 }}
        noResultsMessage=""
        onKeyPress={e => {
            e.key === "Enter" && e.preventDefault();
        }}
        fluid
        name={config.fieldName}
        selection
        search
        className="field"
        onChange={handleChange}
        onSearchChange={handleSearch}
        value={value}
        options={options}
        allowAdditions={config.fieldName === "zip" ? true : false}
        onAddItem={handleAdd}
        additionLabel=""
        placeholder={getTextByKey("suggestions")+getTextByKey("delimit")}
    />
);

InputComponentConstants.dropdownMultipleInput = (
    config,
    handleChange,
    handleAdd,
    handleSearch,
    value,
    options
) => (
    <Dropdown
        style={{ margin: 0 }}
        noResultsMessage=""
        onKeyPress={e => {
            e.key === "Enter" && e.preventDefault();
        }}
        fluid
        name={config.fieldName}
        selection
        search
        multiple
        className="field"
        allowAdditions
        closeOnChange
        additionLabel=""
        onAddItem={handleAdd}
        onChange={handleChange}
        onSearchChange={handleSearch}
        value={value || []}
        options={options}
        placeholder={getTextByKey("suggestions")+getTextByKey("delimit")}
    />
);

InputComponentConstants.dropdownFree = (
    config,
    handleChange,
    handleAdd,
    handleSearch,
    value,
    options
) => (
    <Dropdown
        style={{ margin: 0 }}
        noResultsMessage=""
        onKeyPress={e => {
            e.key === "Enter" && e.preventDefault();
        }}
        fluid
        allowAdditions
        onAddItem={handleAdd}
        name={config.fieldName}
        selection
        search
        closeOnChange
        multiple
        additionLabel=""
        className="field"
        onChange={handleChange}
        onSearchChange={handleSearch}
        value={value || []}
        options={options}
        placeholder={getTextByKey("whatever")+getTextByKey("delimit")}
    />
);

InputComponentConstants.number = (config, handleChange, value) => (
    <Input
        onKeyPress={e => {
            e.key === "Enter" && e.preventDefault();
        }}
        type="number"
        fluid
        placeholder={getTextByKey("type")}
        onChange={handleChange([config.fieldName])}
        value={value || ""}
    />
);

export default InputComponentConstants;
