import React from "react";
import { Button, Grid, Input, Message } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import TokenUtil from "../Util/TokenUtil";
import Constants from "../Util/Constants";
import CookieUtil from "../Util/CookieUtil";
import MiscTextEng from "../miscJson/miscText_eng";
import MiscTextKor from "../miscJson/miscText_kor";
import MiscTextJpn from "../miscJson/miscText_jpn";
import MiscTextDeu from "../miscJson/miscText_deu";
import MiscTextPor from "../miscJson/miscText_por";
import MiscTextSpa from "../miscJson/miscText_spa";
import MiscTextAra from "../miscJson/miscText_ara";
import MiscTextFra from "../miscJson/miscText_fra";
import MiscTextHin from "../miscJson/miscText_hin";
import MiscTextIta from "../miscJson/miscText_ita";
import MiscTextRus from "../miscJson/miscText_rus";
import MiscTextHbs from "../miscJson/miscText_hbs";

let miscText = MiscTextEng;

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            passMatched: false,
            languageSetting: props.location.languageSetting
        };
		let lang = CookieUtil.getCookie("lang");
		if (lang);
		else lang = "eng";
		if (this.state.languageSetting);
		else {
		    this.state.languageSetting = lang;
		}
    }

	updateLanguage = langCode => {
        switch (langCode) {
            case "eng":
                miscText = MiscTextEng;
                break;
            case "kor":
                miscText = MiscTextKor;
                break;
            case "jpn":
                miscText = MiscTextJpn;
                break;
            case "deu":
                miscText = MiscTextDeu;
                break;
            case "por":
                miscText = MiscTextPor;
                break;
            case "spa":
                miscText = MiscTextSpa;
                break;
            case "ara":
                miscText = MiscTextAra;
                break;
            case "fra":
                miscText = MiscTextFra;
                break;
            case "hin":
                miscText = MiscTextHin;
                break;
            case "ita":
                miscText = MiscTextIta;
                break;
            case "rus":
                miscText = MiscTextRus;
                break;
            case "hbs":
                miscText = MiscTextHbs;
                break;
            default:
                miscText = MiscTextEng;
        }
    };

    getParams = function(url) {
        var params = {};
        var parser = document.createElement("a");
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };

    handleClick = async () => {
        console.log("recover user action");

        let params = this.getParams(this.props.location.search);
        let recoverToken = params.recovery;
        console.log(recoverToken);

        var token = TokenUtil.createToken(this.state.email);
        var url =
            Constants.baseApiUrl +
            "action=recovery" +
            "&token=" +
            token +
            "&email=" +
            this.state.email +
            "&password=" +
            this.state.password +
            "&recovery=" +
            recoverToken;

        url = encodeURI(url);
        console.log(url);

        await axios
            .get(url)
            .then(res => {
                console.log("recover user response: ", res);
                if (res.data.status === "success") {
                    this.setState({ redirect: true });
                } else {
                    this.setState({
                        error: true,
                        errorMessage: res.data.message
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    handleChange = (e, data) => {
        this.setState({ [data.name]: data.value });

        if (data.name === "confirmPassword") {
            if (data.value === this.state.password) {
                this.setState({ passMatched: true });
            } else {
                this.setState({ passMatched: false });
            }
        } else if (data.name === "password") {
            if (data.value === this.state.confirmPassword) {
                this.setState({ passMatched: true });
            } else {
                this.setState({ passMatched: false });
            }
        }
    };

    handleConfirmPasswordBlur = () => {};

    render() {
        this.updateLanguage(this.state.languageSetting);
        if (this.state.redirect) {
			let lang = CookieUtil.getCookie("lang");
			if (lang);
			else lang = "eng";
            return (
                <Redirect
                    to={{
                        pathname: "/Login",
                        // email: this.state.email,
                        languageSetting: lang,/*this.props.location.languageSetting,*/
                        isRecovery: true
                        // userData: this.state.userData
                    }}
                />
            );
        }

        let buttonDisabled = true;
        if (
            this.state.email &&
            this.state.confirmPassword &&
            this.state.passMatched
        ) {
            buttonDisabled = false;
        }

        return (
            <Grid
                textAlign="center"
                style={{ height: "100vh" }}
                verticalAlign="middle"
            >
                <Grid.Column style={{ maxWidth: 450 }}>
                    {this.state.error && (
                        <Message error content={this.state.errorMessage} />
                    )}
                    <Input
                        style={{ marginBottom: 10 }}
                        fluid
                        icon="user"
                        iconPosition="left"
                        placeholder={miscText["email"]}
                        onChange={this.handleChange}
                        name="email"
                    />
                    <Input
                        fluid
                        icon="lock"
                        iconPosition="left"
                        placeholder={miscText["password"]}
                        type="password"
                        name="password"
                        onChange={this.handleChange}
                        onBlur={this.handleConfirmPasswordBlur}
                    />
                    <Input
                        error={
                            this.state.confirmPassword &&
                            !this.state.passMatched
                        }
                        fluid
                        icon="lock"
                        iconPosition="left"
                        placeholder={miscText["confirm"]}
                        type="password"
                        name="confirmPassword"
                        onChange={this.handleChange}
                        onBlur={this.handleConfirmPasswordBlur}
                    />
                    <Button
                        color="teal"
                        fluid
                        size="large"
                        onClick={this.handleClick}
                        style={{ marginTop: 10 }}
                        disabled={buttonDisabled}
                    >
                        {miscText["next"]}
                    </Button>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Register;
