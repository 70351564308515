import React from "react";
import { Dropdown, Flag } from "semantic-ui-react";
import CookieUtil from "../Util/CookieUtil";
import { changeLanguage } from "../actions/index.js";
import { connect } from "react-redux";

// const languageSettingOptions = [
//     { key: "eng", value: "eng", text: "English" },
//     { key: "jpn", value: "jpn", text: "日本語" },
//     { key: "kor", value: "kor", text: "한국어" },
//     { key: "spa", value: "spa", text: "español" },
//     { key: "fra", value: "fra", text: "française" },
//     { key: "por", value: "por", text: "portugues" },
//     { key: "ara", value: "ara", text: "عربى" },
//     { key: "deu", value: "deu", text: "Deutsche" },
//     { key: "hin", value: "hin", text: "हिंदी" },
//     { key: "ita", value: "ita", text: "italiano" },
//     { key: "rus", value: "rus", text: "русский" },
//     { key: "hbs", value: "hbs", text: "српски" }
// ];
const languageSettingOptions = [
    { key: "eng", countryCode: "us", text: "English" },
    { key: "jpn", countryCode: "jp", text: "日本語" },
    { key: "kor", countryCode: "kr", text: "한국어" },
    { key: "spa", countryCode: "es", text: "español" },
    { key: "fra", countryCode: "fr", text: "française" },
    { key: "por", countryCode: "pt", text: "portugues" },
    { key: "ara", countryCode: "eg", text: "عربى" },
    { key: "deu", countryCode: "de", text: "Deutsche" },
    { key: "hin", countryCode: "in", text: "हिंदी" },
    { key: "ita", countryCode: "it", text: "italiano" },
    { key: "rus", countryCode: "ru", text: "русский" },
    { key: "hbs", countryCode: "rs", text: "српски" }
];

class LanguagePicker extends React.Component {
    constructor(props) {
        super(props);
        let langCookie =
            this.props.languageSetting || CookieUtil.getCookie("lang") || "eng";
        // console.log("langCookie = ", langCookie)
        this.state = {
            languageSetting: langCookie
        };
    }

    findLangText = () => {
        let langText = "";

        languageSettingOptions.forEach(language => {
            if (language.key === this.state.languageSetting) {
                langText = language.text;
            }
        });

        return langText;
    };

    findCountryCode = () => {
        let countryCode = "";

        languageSettingOptions.forEach(language => {
            if (language.key === this.state.languageSetting) {
                countryCode = language.countryCode;
            }
        });

        return countryCode;
    };

    handleChange = (e, data) => {
        document.cookie = "lang=" + data.langkey;
        this.setState({
            languageSetting: data.langkey
        });
        this.props.handleChange(data.langkey);
        this.props.changeLanguage(data.langkey);
    };

    render() {
        // console.log("state: ", this.state);
        let items = [];
        let langText = this.findLangText();
        let countryCode = this.findCountryCode();
        return (
            <Dropdown
                trigger={
                    <div>
                        <Flag style={{ float: "left" }} name={countryCode} />
                        {langText}
                    </div>
                }
                icon="world"
                floating
                labeled
                button
                className="icon"
                style={{
                    position: "fixed",
                    bottom: 0,
                    backgroundColor: "black",
                    color: "white",
                    width: 170
                }}
            >
                <Dropdown.Menu>
                    {languageSettingOptions.forEach(language => {
                        items.push(
                            <Dropdown.Item
                                key={language.key}
                                icon={
                                    <Flag
                                        name={language.countryCode}
                                        style={{ float: "left" }}
                                    />
                                }
                                langkey={language.key}
                                text={language.text}
                                onClick={this.handleChange}
                            />
                        );
                    })}
                    {items}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

// function mapStateToProps(state) {
//     return {
//         loggedIn: state.loggedIn,
//         languageSetting: state.languageSetting
//     };
// }

function mapDispatchToProps(dispatch) {
    return {
        changeLanguage: languageSetting => {
            dispatch(changeLanguage(languageSetting));
        }
    };
}

export default connect(
    null,
    mapDispatchToProps
)(LanguagePicker);
