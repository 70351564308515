import React from "react";
import {
    Button,
    Form,
    Grid,
    Header,
    TextArea,
    Message
} from "semantic-ui-react";
import InputComponentConstants from "../Util/InputComponentConstants";
import ApiAction from "../Util/ApiAction";
import { Redirect } from "react-router-dom";
import CookieUtil from "../Util/CookieUtil";
import FieldsEng from "../fieldJson/field_eng.json";
import FieldsDeu from "../fieldJson/field_deu.json";
import FieldsKor from "../fieldJson/field_kor.json";
import FieldsJpn from "../fieldJson/field_jpn.json";
import FieldsPor from "../fieldJson/field_por.json";
import FieldsSpa from "../fieldJson/field_spa.json";
import FieldsAra from "../fieldJson/field_ara.json";
import FieldsFra from "../fieldJson/field_fra.json";
import FieldsHin from "../fieldJson/field_hin.json";
import FieldsIta from "../fieldJson/field_ita.json";
import FieldsRus from "../fieldJson/field_rus.json";
import FieldsHbs from "../fieldJson/field_hbs.json";
import MiscTextEng from "../miscJson/miscText_eng";
import MiscTextKor from "../miscJson/miscText_kor";
import MiscTextJpn from "../miscJson/miscText_jpn";
import MiscTextDeu from "../miscJson/miscText_deu";
import MiscTextPor from "../miscJson/miscText_por";
import MiscTextSpa from "../miscJson/miscText_spa";
import MiscTextAra from "../miscJson/miscText_ara";
import MiscTextFra from "../miscJson/miscText_fra";
import MiscTextHin from "../miscJson/miscText_hin";
import MiscTextIta from "../miscJson/miscText_ita";
import MiscTextRus from "../miscJson/miscText_rus";
import MiscTextHbs from "../miscJson/miscText_hbs";

let originalConfig = FieldsEng;
let miscText = MiscTextEng;

class Questionairre extends React.Component {
    constructor(props) {
        super(props);

        let lang = CookieUtil.getCookie("lang") || "eng";

        switch (lang /*props.location.languageSetting*/) {
            case "eng":
                originalConfig = FieldsEng;
                break;
            case "kor":
                originalConfig = FieldsKor;
                break;
            case "jpn":
                originalConfig = FieldsJpn;
                break;
            case "deu":
                originalConfig = FieldsDeu;
                break;
            case "por":
                originalConfig = FieldsPor;
                break;
            case "spa":
                originalConfig = FieldsSpa;
                break;
            case "ara":
                originalConfig = FieldsAra;
                break;
            case "fra":
                originalConfig = FieldsFra;
                break;
            case "hin":
                originalConfig = FieldsHin;
                break;
            case "ita":
                originalConfig = FieldsIta;
                break;
            case "rus":
                originalConfig = FieldsRus;
                break;
            case "hbs":
                originalConfig = FieldsHbs;
                break;
            default:
                originalConfig = FieldsEng;
        }

        let configJson = [];
        originalConfig.forEach(e => {
            if (e.required) configJson.push(e);
        });

        this.state = {
            currentIndex: +CookieUtil.getCookie("currentIndex"),
            email: this.props.location.email,
            configJson: configJson,
            /*languageSetting: lang,*/
            langCode: lang
        };
    }

    updateLanguage = langCode => {
        switch (langCode) {
            case "eng":
                miscText = MiscTextEng;
                break;
            case "kor":
                miscText = MiscTextKor;
                break;
            case "jpn":
                miscText = MiscTextJpn;
                break;
            case "deu":
                miscText = MiscTextDeu;
                break;
            case "por":
                miscText = MiscTextPor;
                break;
            case "spa":
                miscText = MiscTextSpa;
                break;
            case "ara":
                miscText = MiscTextAra;
                break;
            case "fra":
                miscText = MiscTextFra;
                break;
            case "hin":
                miscText = MiscTextHin;
                break;
            case "ita":
                miscText = MiscTextIta;
                break;
            case "rus":
                miscText = MiscTextRus;
                break;
            case "hbs":
                miscText = MiscTextHbs;
                break;
            default:
                miscText = MiscTextEng;
        }
    };

    componentDidMount() {
        var defaultState = {};
        this.state.configJson.forEach(e => {
            defaultState[e.fieldName] = "";
        });
        this.setState(defaultState);
        document.cookie = "currentPage=questionairre";
    }

    handleNextClick = async () => {
        if (!this.state.buttonLoading) {
			
			var currentIndex = this.state.currentIndex;
			var increment = 1;
			
			if (currentIndex < this.state.configJson.length) {
                var currentFieldName = this.state.configJson[currentIndex]
                    .fieldName;

                await ApiAction.updateUser(
                    currentFieldName,
                    this.state[currentFieldName],
                    this.state.email
                );
			}

            
			while(true){
				if (this.state.currentIndex + increment >= this.state.configJson.length){
					break;
				}
				let dependsOn = this.state.configJson[this.state.currentIndex + increment]
					.dependsOn;
				let isDependsOnSatisfied = false;
				if (dependsOn) {
					dependsOn.forEach(dependency => {
						if(this.isDependsOnSatisfied(dependency.fieldName, dependency.value)){
							isDependsOnSatisfied = true;
						}
					});
				}
				if (
					dependsOn && 
					!isDependsOnSatisfied
				) {
					increment = increment + 1;
					if (this.state.currentIndex + increment >= this.state.configJson.length){
						break;
					}
				}else {
					break;
				}
			}
			
            if (currentIndex < this.state.configJson.length) {
                //reset error message
                this.setState({ error: false, messageText: "" });

                //wait for response from update api
                this.setState({ buttonLoading: true });

                if (currentIndex  + increment < this.state.configJson.length) {
                    var nextFieldName = this.state.configJson[currentIndex + increment]
                        .fieldName;

                    if (
                        this.state.configJson[currentIndex + increment].inputType ===
                            "dropdown single category" ||
                        this.state.configJson[currentIndex + increment].inputType ===
                            "dropdown multiple category"
                    ) {
                        var options = await ApiAction.getSuggestValuesByCategory(
                            nextFieldName,
                            this.state.email
                        );
                        this.setState({ [nextFieldName + "Options"]: options });
                    }

                    this.setState({
                        [currentFieldName + "Options"]: [],
                        currentIndex: currentIndex + increment
                    });
                    document.cookie = "currentIndex=" + this.state.currentIndex;
                } else {
                    this.setState({ finished: true });
                }
                this.setState({ buttonLoading: false });
            }else {
                this.setState({ finished: true });
            }
        }
    };

    handleMultipleInputDropdownSearchChange = async (e, data) => {
        var fieldName = data.name;
        var value = data.searchQuery;

        var newOptions =
            (await ApiAction.getSuggestValuesByInput(
                fieldName,
                value,
                this.state.email
            )) || [];

        var oldOptions = this.state[fieldName + "Options"] || [];

        var setOptions = new Set();
        oldOptions.forEach(e => {
            setOptions.add(e.key.toUpperCase());
        });
        newOptions.forEach(e => {
            setOptions.add(e.key); //.toUpperCase()
        });
        var finalOptions = [];
        setOptions.forEach(e => {
            finalOptions.push({ key: e, value: e, text: e });
        });

        this.setState({
            [fieldName + "Options"]: finalOptions
        });

        this.setState({
            [fieldName + "Options"]: finalOptions
        });
    };

    handleSingleDropdownInputChange = async (e, data) => {
        //debugger;
        this.setState({
            [data.name]: data.value.toUpperCase()
        });
        if (this.state.finished) {
            let res = await ApiAction.updateUser(
                data.name,
                data.value,
                this.state.email
            );
            if (res.data.message === "Invalid security token.") {
                this.props.logout();
            }
        }
        //debugger;
    };

    handleSingleDropdownInputSearch = async (e, data) => {
        //debugger;
        var fieldName = data.name;
        var value = data.searchQuery;
        var newOptions = await ApiAction.getSuggestValuesByInput(
            fieldName,
            value,
            this.state.email
        );

        this.setState({
            [fieldName + "Options"]: newOptions
        });
        //debugger;
    };

    handleMultipleDropdownChange = (e, data) => {
        this.setState({
            [data.name]: data.value.map((val, key) => {
                return val.toUpperCase();
            })
        });
        if (this.state.finished) {
            let res = ApiAction.updateUser(
                data.name,
                data.value,
                this.state.email
            );
            if (res.data.message === "Invalid security token.") {
                this.props.logout();
            }
        }
    };

    handleSingleDropdownChange = (e, data) => {
        //debugger;
        this.setState({ [data.name]: data.value.toUpperCase() });

        if (this.state.finished) {
            let res = ApiAction.updateUser(
                data.name,
                data.value,
                this.state.email
            );
            if (res.data.message === "Invalid security token.") {
                this.props.logout();
            }
        }
        //debugger;
    };

    handleTextInputChange = input => e => {
        this.setState({
            [input]: e.target.value
        });

        if (this.state.finished) {
            let res = ApiAction.updateUser(
                input,
                e.target.value,
                this.state.email
            );
            if (res.data.message === "Invalid security token.") {
                this.props.logout();
            }
        }
    };

    handleAddItem = (e, data) => {
        //debugger;
        var newValue = {
            key: data.value.toUpperCase(),
            text: data.value.toUpperCase(),
            value: data.value.toUpperCase()
        };
        var currentValue =
            this.state[
                this.state.configJson[this.state.currentIndex].fieldName +
                    "Options"
            ] || [];
        if (currentValue.find(el => el.key === newValue.key));
        else currentValue.push(newValue);
        this.setState({
            [this.state.configJson[this.state.currentIndex].fieldName +
            "Options"]: currentValue
        });
        //debugger;
    };

    handleFreeDropdownSearch = async (e, data) => {
        var fieldName = this.state.configJson[this.state.currentIndex]
            .fieldName;
        var value = data.searchQuery;

        var newOptions =
            (await ApiAction.getSuggestValuesByInput(
                "",
                value,
                this.state.email
            )) || [];

        var oldOptions = this.state[fieldName + "Options"] || [];

        var setOptions = new Set();
        oldOptions.forEach(e => {
            setOptions.add(e.key.toUpperCase());
        });
        newOptions.forEach(e => {
            setOptions.add(e.key.toUpperCase());
        });
        var finalOptions = [];
        setOptions.forEach(e => {
            finalOptions.push({ key: e, value: e, text: e });
        });

        this.setState({
            [fieldName + "Options"]: finalOptions
        });
    };

    handleCategoryFocus = async (e, data) => {
        //debugger;
        var options = await ApiAction.getSuggestValuesByCategory(
            data.name,
            this.state.email
        );
        this.setState({ [data.name + "Options"]: options });
        //debugger;
    };

    generateSingleInput = config => {
        var input;
        if (config.inputType === "text") {
            input = InputComponentConstants.textInput(
                config,
                this.handleTextInputChange,
                this.state[config.fieldName],
                this.handleBlur
            );
        } else if (config.inputType === "dropdown single category") {
            input = InputComponentConstants.dropdownSingleCategory(
                config,
                this.handleSingleDropdownChange,
                this.handleCategoryFocus,
                this.state[config.fieldName],
                this.state[config.fieldName + "Options"],
                this.handleBlur,
                this.handleAddItem
            );
        } else if (config.inputType === "dropdown multiple category") {
            input = InputComponentConstants.dropdownMultipleCategory(
                config,
                this.handleMultipleDropdownChange,
                this.handleAddItem,
                this.handleCategoryFocus,
                this.state[config.fieldName],
                this.state[config.fieldName + "Options"],
                this.handleBlur
            );
        } else if (config.inputType === "dropdown single input") {
            input = InputComponentConstants.dropdownSingleInput(
                config,
                this.handleSingleDropdownInputChange,
                this.handleSingleDropdownInputSearch,
                this.state[config.fieldName],
                this.state[config.fieldName + "Options"],
                this.handleAddItem
            );
        } else if (config.inputType === "dropdown multiple input") {
            input = InputComponentConstants.dropdownMultipleInput(
                config,
                this.handleMultipleDropdownChange,
                this.handleAddItem,
                this.handleMultipleInputDropdownSearchChange,
                this.state[config.fieldName],
                this.state[config.fieldName + "Options"]
            );
        } else if (config.inputType === "dropdown free") {
            input = InputComponentConstants.dropdownFree(
                config,
                this.handleMultipleDropdownChange,
                this.handleAddItem,
                this.handleMultipleInputDropdownSearchChange,
                this.state[config.fieldName],
                this.state[config.fieldName + "Options"]
            );
        } else if (config.inputType === "number") {
            input = InputComponentConstants.number(
                config,
                this.handleTextInputChange,
                this.state[config.fieldName]
            );
        } else if (config.inputType === "textarea") {
            input = (
                <TextArea
                    style={{ margin: 5 }}
                    fluid
                    placeholder="Type your answer here"
                    onChange={this.handleTextInputChange([config.fieldName])}
                    value={this.state[config.fieldName]}
                />
            );
        }

        return input;
    };

    isDependsOnSatisfied = (fieldName, value) => {
        var dependsOnValue = this.state[fieldName];
        console.log("depends on value:", dependsOnValue);

        if (dependsOnValue === value) {
            return true;
        } else if (
            typeof Array.isArray(dependsOnValue) &&
            dependsOnValue.includes(value.toUpperCase())
        ) {
            return true;
        } else if (fieldName === undefined || value === undefined) {
            return true;
        }

        return false;
    };

    render() {
        this.updateLanguage(this.state.langCode);
        if (this.state.finished) {
            return (
                <Redirect
                    to={{
                        pathname: "/Edit",
                        userData: this.state,
                        showDoneMessage: true
                    }}
                />
            );
        }

        var buttonEnabled = true;
        var currentIndex = this.state.currentIndex;
        if (currentIndex < this.state.configJson.length) {
            var currentFieldValue = this.state[
                this.state.configJson[this.state.currentIndex].fieldName
            ];

            if (
                this.state.configJson[this.state.currentIndex].required &&
                (currentFieldValue === undefined || currentFieldValue === "")
            ) {
                buttonEnabled = false;
            }

            if (
                this.state.configJson[
                    this.state.currentIndex
                ].inputType.endsWith("category") &&
                this.state[
                    this.state.configJson[this.state.currentIndex].fieldName +
                        "Options"
                ] === undefined
            ) {
                buttonEnabled = false;
            }
        }

        return (
            <Grid
                textAlign="center"
                style={{ height: "100vh", width: "100vw" }}
                verticalAlign="middle"
            >
                <Grid.Column style={{ minWidth: 400, maxWidth: 800 }}>
                    <Header as="h2" textAlign="center">
                        {!this.state.finished &&
                            this.state.configJson[this.state.currentIndex]
                                .questionText}
                    </Header>
                    <Form size="large">
                        {!this.state.finished &&
                            this.generateSingleInput(
                                this.state.configJson[this.state.currentIndex]
                            )}

                        {!this.state.finished && (
                            <Button
                                loading={this.state.buttonLoading}
                                disabled={!buttonEnabled}
                                color="teal"
                                fluid
                                size="large"
                                onClick={this.handleNextClick}
                            >
                                {miscText["next"]}
                            </Button>
                        )}
                    </Form>
                    {this.state.error && (
                        <Message error>{this.state.messageText}</Message>
                    )}
                </Grid.Column>
            </Grid>
        );
    }
}

export default Questionairre;
