import md5 from "md5";

class TokenUtil {}

TokenUtil.getTimeWithoutMicro = () => {
    return Date.now()
        .toString()
        .substr(0, 10);
};

TokenUtil.md5s = plainText => {
    var seed = process.env.REACT_APP_SECRET;
    return md5(md5(seed) + md5(plainText));
};

TokenUtil.createToken = email => {
    var timestamp = TokenUtil.getTimeWithoutMicro();
    return (
        TokenUtil.md5s(email + timestamp) +
        TokenUtil.md5s(timestamp) +
        timestamp
    );
};

export default TokenUtil;
