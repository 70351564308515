import axios from "axios";
import TokenUtil from "./TokenUtil";
import Constants from "./Constants";

class ApiAction{}

ApiAction.createUser = async (email, language, password) => {
    console.log("create user action", language);

    var token = TokenUtil.createToken(email);
    var url =
        Constants.baseApiUrl +
        "action=create" +
        "&token=" +
        token +
        "&email=" +
        email +
        "&password=" +
        password +
        "&lang=" +
        language;

    url = encodeURI(url);
    console.log(url);

    return await axios
        .get(url)
        .then(res => {
            console.log("create user response: ", res);
            return res;
        })
        .catch(error => {
            console.log(error);
        });
};

ApiAction.updateUser = async (field, value, email) => {
    console.log("update user action for user ", email);
    console.log("field: " , field, " value:" , value);

    var token = TokenUtil.createToken(email);
    var url =
        Constants.baseApiUrl +
        "action=update" +
        "&email=" +
        email +
        "&token=" +
        token +
        "&field=" +
        field +
        "&value=" +
        value;

    url = encodeURI(url);
    console.log(url);

    return await axios
        .get(url)
        .then(res => {
            console.log("update user response: ", res);
            return res;
        })
        .catch(error => {
            console.log(error);
        });
};

ApiAction.getSuggestValuesByInput = async (category, input, email) => {
    console.log("suggest values by input");

    var token = TokenUtil.createToken(email);

    if (input !== undefined && input.length >= 2) {
        var url =
            Constants.baseApiUrl +
            "action=suggest" +
            "&token=" +
            token +
            "&email=" +
            email +
            "&c=" +
            category +
            "&q=" +
            input;

        url = encodeURI(url);
        console.log(url);

        var options = [];
        return await axios
            .get(url)
            .then(res => {
                const data = res.data;
                console.log(category + " response", data);
                if (data.length !== undefined) {
                    data.forEach(element => {
                        options.push({
                            key: element,
                            value: element,
                            text: element
                        });
                    });
                }

                return options;
            })
            .catch(error => {
                console.log(error);
            });
    }
};

ApiAction.getSuggestValuesByCategory = async (category, email) => {
    console.log("suggest values by category");
    var token = TokenUtil.createToken(email);

    var url =
        Constants.baseApiUrl +
        "action=suggest" +
        "&token=" +
        token +
        "&email=" +
        email +
        "&c=" +
        category;

    url = encodeURI(url);
    console.log(url);

    return await axios
        .get(url)
        .then(res => {
            const data = res.data;
            console.log(category + " response", data);
            var options = [];
            data.forEach(element => {
                options.push({
                    key: element,
                    value: element,
                    text: element
                });
            });

            var key = category + "Options";
            key = key.replace(" ", "");
            var val = options;
            var obj = {};
            obj[key] = val;
            console.log("options:", options);
            return options;
        })
        .catch(error => {
            console.log(error);
        });
};

ApiAction.getUserData = async (email, sessionId) => {
    const token = TokenUtil.createToken(email);

    var url =
        Constants.baseApiUrl +
        "action=login" +
        "&token=" +
        token +
        "&email=" +
        email +
        // "&password=test" +
        "&sessionid=" +
        sessionId;

    url = encodeURI(url);
    console.log(url);

    return await axios
        .get(url)
        .then(res => {
            console.log("response", res);
            if (res.data.status !== "failure") {
                return res;
            }
        })
        .catch(error => {
            console.log(error);
        });
};


export default ApiAction;
