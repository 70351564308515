import React, { Component, Fragment } from "react";
import {
    Container,
    Header,
    Segment,
    Grid,
    Divider,
    Button,
    Icon,
    Image,
    Label
} from "semantic-ui-react";
import Friends from "../photos/friends1_1200-compressor.jpg";
import People from "../photos/people2_1200-compressor.jpg";
import Partners from "../photos/projectPartners1_1200-compressor.jpg";
import Cycling from "../photos/cycling1200-compressor.jpg";
import CookieUtil from "../Util/CookieUtil";
import LanguagePicker from "./LanguagePicker";
import HomepageTextEng from "../homepageJson/homepageText_eng";
import HomepageTextKor from "../homepageJson/homepageText_kor";
import HomepageTextJpn from "../homepageJson/homepageText_jpn";
import HomepageTextDeu from "../homepageJson/homepageText_deu";
import HomepageTextPor from "../homepageJson/homepageText_por";
import HomepageTextSpa from "../homepageJson/homepageText_spa";
import HomepageTextAra from "../homepageJson/homepageText_ara";
import HomepageTextFra from "../homepageJson/homepageText_fra";
import HomepageTextHin from "../homepageJson/homepageText_hin";
import HomepageTextIta from "../homepageJson/homepageText_ita";
import HomepageTextRus from "../homepageJson/homepageText_rus";
import HomepageTextHbs from "../homepageJson/homepageText_hbs";
import MiscTextEng from "../miscJson/miscText_eng";
import MiscTextKor from "../miscJson/miscText_kor";
import MiscTextJpn from "../miscJson/miscText_jpn";
import MiscTextDeu from "../miscJson/miscText_deu";
import MiscTextPor from "../miscJson/miscText_por";
import MiscTextSpa from "../miscJson/miscText_spa";
import MiscTextAra from "../miscJson/miscText_ara";
import MiscTextFra from "../miscJson/miscText_fra";
import MiscTextHin from "../miscJson/miscText_hin";
import MiscTextIta from "../miscJson/miscText_ita";
import MiscTextRus from "../miscJson/miscText_rus";
import MiscTextHbs from "../miscJson/miscText_hbs";
import { Link } from "react-router-dom";
import ParamUtil from "../Util/ParamUtil";
import { changeLanguage } from "../actions/index.js";
import { connect } from "react-redux";

let homepageText = HomepageTextEng;
let miscText = MiscTextEng;

class Home extends Component {
    constructor(props) {
        super(props);
        let lang = CookieUtil.getCookie("lang") || "eng";
        let urlParams = ParamUtil.getParams(this.props.location.search);

        if (urlParams.lang) {
            lang = urlParams.lang;
            document.cookie = "lang=" + lang;
        }

        this.state = {
            languageSetting: lang
        };
    }

    componentDidMount() {
        this.props.changeLanguage(this.state.languageSetting);
    }

    handleLanguageChange = langKey => {
        this.setState({ languageSetting: langKey });
    };

    updateLanguage = langCode => {
        switch (langCode) {
            case "eng":
                homepageText = HomepageTextEng;
                miscText = MiscTextEng;
                break;
            case "kor":
                homepageText = HomepageTextKor;
                miscText = MiscTextKor;
                break;
            case "jpn":
                homepageText = HomepageTextJpn;
                miscText = MiscTextJpn;
                break;
            case "deu":
                homepageText = HomepageTextDeu;
                miscText = MiscTextDeu;
                break;
            case "por":
                homepageText = HomepageTextPor;
                miscText = MiscTextPor;
                break;
            case "spa":
                homepageText = HomepageTextSpa;
                miscText = MiscTextSpa;
                break;
            case "ara":
                homepageText = HomepageTextAra;
                miscText = MiscTextAra;
                break;
            case "fra":
                homepageText = HomepageTextFra;
                miscText = MiscTextFra;
                break;
            case "hin":
                homepageText = HomepageTextHin;
                miscText = MiscTextHin;
                break;
            case "ita":
                homepageText = HomepageTextIta;
                miscText = MiscTextIta;
                break;
            case "rus":
                homepageText = HomepageTextRus;
                miscText = MiscTextRus;
                break;
            case "hbs":
                homepageText = HomepageTextHbs;
                miscText = MiscTextHbs;
                break;
            default:
                homepageText = HomepageTextEng;
                miscText = MiscTextEng;
        }
    };

    testF = bool => {
        return bool;
    };

    render() {
        let width = window.innerWidth;
        this.updateLanguage(this.state.languageSetting);
        return (
            <Fragment>
                <div className="homeBackground">
                    <Segment
                        compact
                        textAlign="center"
                        style={{
                            width: "100%",
                            padding: "4em 0em"
                        }}
                        vertical
                    >
                        <Container>
                            <Header
                                as="h1"
                                content={homepageText.header[0]}
                                style={{
                                    fontSize: "4em",
                                    fontWeight: "normal",
                                    marginBottom: 0,
                                    marginTop: "0em",
                                    textShadow: "2px 2px 4px #000000",
                                    color: "white"
                                }}
                            />
                            <Header
                                as="h1"
                                content={homepageText.header[1]}
                                style={{
                                    fontSize: "3em",
                                    fontWeight: "bold",
                                    marginBottom: "1em",
                                    marginTop: "1em",
                                    textShadow: "2px 2px 4px #000000",
                                    color: "white"
                                }}
                            />
                            <Link
                                to={{
                                    pathname: "/Register",
                                    languageSetting: this.state.languageSetting
                                }}
                            >
                                <Button
                                    style={{ backgroundColor: "#92c5d1" }}
                                    primary
                                    size="huge"
                                >
                                    {miscText["getstarted"]}
                                    <Icon name="right arrow" />
                                </Button>
                            </Link>
                        </Container>
                    </Segment>
                </div>
                <Grid
                    style={{
                        backgroundColor: "#dce2ea",
                        margin: "0, 0, 0, 0",
                        paddingLeft: "0vw",
                        paddingRight: "0vw",
                        paddingBottom: "0"
                    }}
                    // columns={3}
                    centered
                >
                    <Grid.Column
                        mobile={16}
                        computer={4}
                        style={{
                            paddingBottom: "0",
                            paddingTop: "0px",
                            paddingLeft: "0px",
                            paddingRight: "0px"
                        }}
                    >
                        <Image fluid centered src={Friends} />
                        <Label
                            style={{
                                position: "absolute",
                                bottom: "10%",
                                left: "0%",
                                backgroundColor: "white",
                                color: "black"
                            }}
                            ribbon
                        >
                            {homepageText["meetTypes"][0]}
                        </Label>
                    </Grid.Column>
                    <Grid.Column
                        mobile={16}
                        computer={4}
                        style={{
                            paddingBottom: "0",
                            paddingTop: "0px",
                            paddingLeft: "0px",
                            paddingRight: "0px"
                        }}
                    >
                        <Image fluid centered src={Cycling} />
                        <Label
                            style={{
                                position: "absolute",
                                bottom: "10%",
                                left: "0%",
                                backgroundColor: "white",
                                color: "black"
                            }}
                            ribbon
                        >
                            {homepageText["meetTypes"][1]}
                        </Label>
                    </Grid.Column>
                    <Grid.Column
                        mobile={16}
                        computer={4}
                        style={{
                            paddingBottom: "0",
                            paddingTop: "0px",
                            paddingLeft: "0px",
                            paddingRight: "0px"
                        }}
                    >
                        <Image fluid centered src={Partners} />
                        <Label
                            style={{
                                position: "absolute",
                                bottom: "10%",
                                left: "0%",
                                backgroundColor: "white",
                                color: "black"
                            }}
                            ribbon
                        >
                            {homepageText["meetTypes"][2]}
                        </Label>
                    </Grid.Column>
                    <Grid.Column
                        mobile={16}
                        computer={4}
                        style={{
                            paddingBottom: "0",
                            paddingTop: "0px",
                            paddingLeft: "0px",
                            paddingRight: "0px"
                        }}
                    >
                        <Image fluid centered src={People} />
                        <Label
                            style={{
                                position: "absolute",
                                bottom: "10%",
                                left: "0%",

                                backgroundColor: "white",
                                color: "black"
                            }}
                            ribbon
                        >
                            {homepageText["meetTypes"][3]}
                        </Label>
                    </Grid.Column>
                </Grid>
                <Segment style={{ padding: "8em 0em", width: "100%" }} vertical>
                    <Grid stackable textAlign="center" verticalAlign="top">
                        <Grid.Column width={6}>
                            <Header as="h3" style={{ fontSize: "2em" }}>
                                {homepageText.additionInfo[0].title}
                            </Header>
                            <p style={{ fontSize: "1.33em" }}>
                                {homepageText.additionInfo[0].paragraph}
                            </p>
                            <Header as="h3" style={{ fontSize: "2em" }}>
                                {homepageText.additionInfo[1].title}
                            </Header>
                            <p style={{ fontSize: "1.33em" }}>
                                {homepageText.additionInfo[1].paragraph}
                            </p>
                        </Grid.Column>
                        <Grid.Column width={1} />
                        <Grid.Column width={6}>
                            <Header as="h3" style={{ fontSize: "2em" }}>
                                {homepageText.additionInfo[2].title}
                            </Header>
                            <p style={{ fontSize: "1.33em" }}>
                                {homepageText.additionInfo[2].paragraph}
                            </p>
                            <Header as="h3" style={{ fontSize: "2em" }}>
                                {homepageText.additionInfo[3].title}
                            </Header>
                            <p style={{ fontSize: "1.33em" }}>
                                {homepageText.additionInfo[3].paragraph}
                            </p>
                        </Grid.Column>
                    </Grid>
                    {width >= 767 && <Divider vertical />}
                </Segment>
                {/* <Segment inverted vertical style={{ padding: "2em 0em" }}>
                        <Container>
                            <Grid divided inverted stackable>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Header
                                        inverted
                                        as="h4"
                                        content={
                                            homepageText.footer.about.title
                                        }
                                    />
                                    <List link inverted>
                                        <List.Item as="a">
                                            {homepageText.footer.about.items[0]}
                                        </List.Item>
                                        <List.Item as="a">
                                            {homepageText.footer.about.items[1]}
                                        </List.Item>
                                        <List.Item as="a">
                                            {homepageText.footer.about.items[2]}
                                        </List.Item>
                                        <List.Item as="a">
                                            {homepageText.footer.about.items[3]}
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Header
                                        inverted
                                        as="h4"
                                        content={
                                            homepageText.footer.services.title
                                        }
                                    />
                                    <List link inverted>
                                        <List.Item as="a">
                                            {
                                                homepageText.footer.services
                                                    .items[0]
                                            }
                                        </List.Item>
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        </Container>
                    </Segment> */}

                <LanguagePicker
                    handleChange={this.handleLanguageChange}
                    languageSetting={this.state.languageSetting}
                />
            </Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changeLanguage: languageSetting => {
            dispatch(changeLanguage(languageSetting));
        }
    };
}

export default connect(
    null,
    mapDispatchToProps
)(Home);
