import React from "react";
import "./App.css";
import Login from "./components/Login";
import Home from "./components/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Questionairre from "./components/Questionairre";
import ProfileEdit from "./components/ProfileEdit";
import Register from "./components/Register";
import Recover from "./components/Recover";
import Navigation from "./components/Navigation";
import store from "./store/index";
import { Segment, Container } from "semantic-ui-react";

function App() {
    require("dotenv").config();
    document.title = "FOMO Match";

    return (
        <div
            className="body"
            style={{
                minWidth: "100vw",
                minHeight: "100vh",
                backgroundColor: "#dce2ea"
            }}
        >
            <Router>
                <Navigation store={store} />

                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/Login" component={Login} />
                    <Route path="/Recover" component={Recover} />
                    <Route path="/Register" component={Register} />
                    <Route path="/Questions" component={Questionairre} />
                    <Route path="/Edit" component={ProfileEdit} />
                </Switch>
            </Router>
            <div
                style={{
                    backgroundColor: "black",
                    color: "white",
                    width: "100vw",
                    paddingBottom: "2em"
                }}
            >
                <div style={{ float: "right", marginRight: "2vw" }}>
                    &copy;2019 fomomatch.com
                </div>
            </div>
        </div>
    );
}

export default App;
