import React from "react";
import { Link } from "react-router-dom";
import { Image, Button, Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { logout } from "../actions";
import CookieUtil from "../Util/CookieUtil";
import MiscTextEng from "../miscJson/miscText_eng";
import MiscTextKor from "../miscJson/miscText_kor";
import MiscTextJpn from "../miscJson/miscText_jpn";
import MiscTextDeu from "../miscJson/miscText_deu";
import MiscTextPor from "../miscJson/miscText_por";
import MiscTextSpa from "../miscJson/miscText_spa";
import MiscTextAra from "../miscJson/miscText_ara";
import MiscTextFra from "../miscJson/miscText_fra";
import MiscTextHin from "../miscJson/miscText_hin";
import MiscTextIta from "../miscJson/miscText_ita";
import MiscTextRus from "../miscJson/miscText_rus";
import MiscTextHbs from "../miscJson/miscText_hbs";
import logo from "../photos/fomo-removebg.png";

let miscText = MiscTextEng;

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        let lang =
            this.props.languageSetting || CookieUtil.getCookie("lang") || "eng";
        this.state = {
            currentPage: CookieUtil.getCookie("currentPage"),
            languageSetting: lang
        };
    }

    handleLoginClick = () => {
        this.props.logout();
    };

    componentWillReceiveProps(newProps) {
        this.setState({ languageSetting: newProps.languageSetting });
    }

    updateLanguage = langCode => {
        switch (langCode) {
            case "eng":
                miscText = MiscTextEng;
                break;
            case "kor":
                miscText = MiscTextKor;
                break;
            case "jpn":
                miscText = MiscTextJpn;
                break;
            case "deu":
                miscText = MiscTextDeu;
                break;
            case "por":
                miscText = MiscTextPor;
                break;
            case "spa":
                miscText = MiscTextSpa;
                break;
            case "ara":
                miscText = MiscTextAra;
                break;
            case "fra":
                miscText = MiscTextFra;
                break;
            case "hin":
                miscText = MiscTextHin;
                break;
            case "ita":
                miscText = MiscTextIta;
                break;
            case "rus":
                miscText = MiscTextRus;
                break;
            case "hbs":
                miscText = MiscTextHbs;
                break;
            default:
                miscText = MiscTextEng;
        }
    };

    render() {
        this.updateLanguage(this.state.languageSetting);
        return (
            <Container style={{ width: "100%" }}>
                <Link to="/">
                    <Image size="tiny"
                        style={{
                            cursor: "pointer",
                            float: "left",
                            margin: 15,
                        }}
                        src={logo}
                    />
                </Link>
                <Link to="/Login">
                    <Button
                        content={
                            this.props.loggedIn
                                ? miscText["logout"]
                                : miscText["login"]
                        }
                        style={{
                            float: "right",
                            backgroundColor: "#FFFFFF",
                            margin: 10,
                            marginRight: 20
                        }}
                        onClick={this.handleLoginClick}
                    />
                </Link>

                {this.props.loggedIn && (
                        <Link
                            to={{
                                pathname: "/Edit"
                            }}
                        >
                            <Button
                                content={"View Profile"}
                                style={{
                                    float: "right",
                                    backgroundColor: "#FFFFFF",
                                    margin: 10,
                                    marginRight: 20
                                }}
                            />
                        </Link>
                    )}
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn,
        languageSetting: state.languageSetting
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => {
            document.cookie = "loggedIn=false";
            dispatch(logout());
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);
